import { IAccount } from "@core/account/interface";
import { makeAutoObservable, runInAction } from "mobx";
import * as dasha from "@dasha.ai/sdk/web";
import {
  IHistoryMessage,
  PromptCreateDTO,
  PromptData,
  PromptPatchDTO,
  PromptVersionCreateDTO,
  PromptVersionDTO,
  TestCaseCreateDTO,
  TestCaseDTO,
  TestCasePatchDTO,
  TestCaseResultDTO,
  TestPromptDTO,

} from "@dasha.ai/sdk/web/rest-api/generated/testsystem";
import { GptSingleEmulatedResponse } from "@dasha.ai/sdk/web/gpt";
import { PromptVersion } from "./PromptVersion";
import { TestCase } from "./TestCase";

export class PromptAPI {
  private account: IAccount;
  constructor(account: IAccount) {
    this.account = account;
  }

  async fetchPromptsAsync(skip: number, take: number): Promise<Prompt[]> {
    const a = this.account.connect();
    const response = await dasha.prompt.listPrompts(skip, take, { account: a });
    return response.map((x) => new Prompt(x, this, this.account));
  }

  async deletePromptAsync(id: string) {
    const a = this.account.connect();
    await dasha.prompt.deletePrompt(id, { account: a });
  }

  async createPromptAsync(dto: PromptCreateDTO): Promise<Prompt> {
    const a = this.account.connect();
    const response = await dasha.prompt.addPrompt(dto, { account: a });
    return new Prompt(response, this, this.account);
  }

  async updatePromptAsync(id: string, dto: PromptPatchDTO): Promise<Prompt> {
    const a = this.account.connect();
    const response = await dasha.prompt.updatePrompt(id, dto, { account: a });
    return new Prompt(response, this, this.account);
  }

  async getPromptAsync(id: string): Promise<Prompt> {
    const a = this.account.connect();
    const response = await dasha.prompt.getPrompt(id, { account: a});
    return new Prompt(response, this, this.account);
  }

  async addPromptVersionAsync(promptId: string, dto: PromptVersionCreateDTO): Promise<PromptVersion> {
    const a = this.account.connect();
    const response = await dasha.prompt.addPromptVersion(promptId, dto, { account: a});
    return new PromptVersion(response, this);
  }

  async addTestAsync(dto: TestCaseCreateDTO): Promise<TestCase> {
    const a = this.account.connect();
    const response = await dasha.testsystem.createTestCase(dto, { account: a });
    return new TestCase(response, this);
  }

  async listVersions(promptId: string, take: number, skip: number): Promise<PromptVersion[]> {
    const a = this.account.connect();
    const response = await dasha.prompt.listPromptVersions(promptId, skip, take, { account: a});
    return response.map((x)=>new PromptVersion(x, this));
  }

  async listTestCases(promptId: string, take: number, skip: number): Promise<TestCase[]> {
    const a = this.account.connect();
    const response = await dasha.testsystem.listTestCases(promptId, skip, take, { account: a});
    return response.map((x)=>new TestCase(x, this));
  }

  async deleteTestCase(id: string): Promise<void> {
    const a = this.account.connect();
    //TODO: add delete logic
    await dasha.testsystem.deleteTestCase(id, {account: a});
  }

  async updateTestCase(id: string, dto: TestCasePatchDTO) {
    const a = this.account.connect();
    await dasha.testsystem.updateTestCase(id, dto, { account: a});
  }
}

export class PromptStore {
  public Prompts: Prompt[];
  private api: PromptAPI;
  private pageSize = 50;
  public loading: boolean;

  constructor(account: IAccount) {
    this.api = new PromptAPI(account);
    this.Prompts = [];
    makeAutoObservable(this);
  }

  public getByName(name: string): Prompt | undefined {
    var filter = this.Prompts.filter((x) => x.name === name);
    return filter[0];
  }

  public async getAsync(id: string): Promise<Prompt> {
    return this.api.getPromptAsync(id);
  }

  public async addTestAsync(dto: TestCaseCreateDTO): Promise<TestCase> {
    var test = await this.api.addTestAsync(dto);
    this.loadPrompts(true);
    return test;
  }

  public loadPrompts(force: boolean = false) {
    if (this.Prompts.length > 0 && !force) {
      return;
    }

    this.loading = true;
    this.api
      .fetchPromptsAsync(0, this.pageSize)
      .then((x) => {
        this.Prompts = x;
      })
      .catch((e) => {
        console.error("Failed to load prompts",e)
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public deletePrompt(id: string) {
    this.Prompts = this.Prompts.filter((x) => x.id !== id);
    this.api.deletePromptAsync(id);
  }

  public async savePromptChangesAsync(prompt: Prompt): Promise<void> {
    if (prompt.id === undefined) {
      const result = await this.api.createPromptAsync({
        promptName: prompt.name,
        description: prompt.description,
      });
      prompt.id = result.id;
    } else {
      await this.api.updatePromptAsync(prompt.id, {
        promptName: prompt.name,
        description: prompt.description,
      });
    }
  }

  public async createPromptAsync(dto: PromptCreateDTO): Promise<Prompt> {
    const prompt = await this.api.createPromptAsync(dto);
    runInAction(() => {
      this.Prompts.push(prompt);
    });

    return prompt;
  }
}

export class Prompt  {
  public id: string;
  public name: string;
  public description: string;
  private api: PromptAPI;
  public versions: PromptVersion[];
  public testCases: TestCase[];

  constructor(dto: TestPromptDTO, api: PromptAPI, private readonly account: IAccount) {
    this.id = dto.id!;
    this.name = dto.promptName ?? "";
    this.description = dto.description ?? "";
    this.api = api;
    this.versions = [];
    this.testCases = [];
    makeAutoObservable(this);
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public setName(name: string) {
    this.name = name;
  }

  public async saveChangesAsync(): Promise<void> {
    await this.api.updatePromptAsync(this.id, {
      description: this.description,
      promptName: this.name
    });
  }

  public async AddVersionAsync(description: string, data: PromptData): Promise<PromptVersion> {
    const newVer = await this.api.addPromptVersionAsync(this.id, { data: data, description: description});
    runInAction(() => {
      this.versions = [newVer, ...this.versions];
    });

    return newVer;
  }

  public async AddTestCaseAsync(dto: TestCaseCreateDTO): Promise<TestCase> {
    const test = await this.api.addTestAsync({...dto, ...{ promptId: this.id }});
    runInAction(() => {
      this.testCases = [test, ...this.testCases];
    });

    return test;
  }

  public async LoadVersionsAsync(take: number = 50, skip: number = 0): Promise<void> {
    const versions = await this.api.listVersions(this.id, take, skip);
    runInAction(() => {
      this.versions = versions;
    })
  }

  public async LoadTestCasesAsync(take: number = 50, skip: number = 0): Promise<void> {
    const testCases = await this.api.listTestCases(this.id, take, skip);
    runInAction(() => {
      this.testCases = testCases;
    })
  }

  public runTestCase(test: TestCase) {
    test.setStatus("Queued");
  }

  public async deleteTestCase(id: string) {
    await this.api.deleteTestCase(id);
    runInAction(() => {
      this.testCases = this.testCases.filter((x) => x.id !== id);
    });
  }

  public getTestCase(id: string): TestCase | undefined {
    return this.testCases.find((x)=>x.id === id);
  }



  
  private isCompletedStatus(status: string) {
    switch (status) {
      case "Queued":
      case "InProgress":
        return false;
    }
    return true;
  }

  async emulateAsync(data: PromptData, numTries: number, tests: string[]): Promise<GptSingleEmulatedResponse[]> {
    if (tests.length === 0) {
      return [];
    }
    const testCases = tests.map((x)=>this.getTestCase(x)).filter((x)=>x!==undefined) as TestCase[];
    testCases.forEach((x) => { x.setTestResult([]); x.setStatus("Queued"); });

    const a = this.account.connect();
    const response = await dasha.testsystem.RunTestCases(
      {
        testData: data,
        promptId: this.id,
        testCaseIds: tests,
        numTries: numTries
      },
      { account: a }
    );

    while (response.runId !== undefined) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const results = await dasha.testsystem.GetTestRunResults(response.runId, { account: a });
      const resultMapping = new Map<string, TestCaseResultDTO[]>();

      for (var result of results) {
        if (!resultMapping.has(result.testCaseId!)) {
          resultMapping.set(result.testCaseId!, []);
        }
        resultMapping.get(result.testCaseId!)?.push(result);
      }

      for (var [k, v] of resultMapping) {

        if (v.every((x) => this.isCompletedStatus(x.status ?? "Unknown"))) {
          const test = testCases.find((x) => x.id === k);
          test?.setTestResult(v);
        }
      }
      if (results.every((x) => this.isCompletedStatus(x.status ?? "Unknown"))) {

        return [];
      }
    }

    return [];
  }

}
