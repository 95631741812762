import React, { FC, useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";

import { getDateWithTime } from "./helpers";
import { RangeDate } from "./types";

import * as S from "./styled";

interface Props {
  dateRange: {
    start: Date;
    end: Date;
  };
  onChange: (dates: [RangeDate, RangeDate]) => void;
}

const DATE_FORMAT = "DD.MM.YYYY";

const DateSelector: FC<Props> = ({ dateRange, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerEl.current && !containerEl.current.contains(e.target)) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handlePickerChange = (ranges) => {
    console.log();
    onChange([
      getDateWithTime(ranges.selection.startDate, "00:00"),
      getDateWithTime(ranges.selection.endDate, "23:59"),
    ]);
    //onChange([getDateWithTime(min, "00:00"), max ? getDateWithTime(max, "23:59") : null]);
  };

  const selectionRange = {
    startDate: dateRange.start,
    endDate: dateRange.end,
    key: "selection",
  };

  return (
    <S.Container ref={containerEl} $dateRange={dateRange}>
      {!isOpen && (
        <S.CustomInput>
          {dayjs(dateRange.start).format(DATE_FORMAT)} — {dayjs(dateRange.end).format(DATE_FORMAT)}
          <CalendarIcon />
        </S.CustomInput>  
      )}
      {isOpen && <DateRangePicker ranges={[selectionRange]} onChange={handlePickerChange} />}
    </S.Container>
  );
};

export default DateSelector;
