import { observer } from "mobx-react-lite";
import React, { FC } from "react";

import { Icon } from "../uikit";
import Workspace from "../core/workspace/Workspace";
import AccountsManager from "../core/account/Manager";
import Explorer from "../core/explorer/Explorer";

import AccountWidget from "./AccountWidget";
import ProjectActions from "./ProjectActions";
import ProjectControl from "./ProjectControl";

import { ReactComponent as LogoIcon } from "./logo.svg";
import * as S from "./styled";
import { PureButton } from "../uikit/Button";
import { Tag } from "../ExplorerView/Tag";
import { Message } from "semantic-ui-react";

interface Props {
  id: string;
  useLogo?: boolean;
  accounts: AccountsManager;
  workspace: Workspace;
  explorer: Explorer;
}

const HeaderPanel: FC<Props> = ({ id, useLogo, workspace, accounts, explorer }) => {
  const { project, isRunning } = workspace;
  const versions = project?.versions || {};
  const customerName = accounts.account.getValue<string>("customerName");

  return (
    <S.Container id={id}>
      {useLogo ? (
        <S.Logo target="_blank" rel="noopener noreferrer" href="https://dasha.ai/">
          <LogoIcon />
        </S.Logo>
      ) : (
        <S.Flexbox>
          <PureButton onClick={() => explorer.open(null)}>
            <Icon name="apps" />
          </PureButton>

          {project?.metadata.isEditable === false && <Tag id="readonly" />}
          {project && Object.keys(versions).length > 1 && (
            <Tag id="custom">{versions[project.version].message ?? "Unnamed"}</Tag>
          )}
        </S.Flexbox>
      )}
      {project && (
        <S.Fullwrap>
          <ProjectActions workspace={workspace} explorer={explorer} project={project} isDisabled={isRunning}>
            <S.Title>
              {project.metadata.name}
              <Icon name="chevron-down" style={{ opacity: isRunning ? 0 : 1 }} />
            </S.Title>
          </ProjectActions>
        </S.Fullwrap>
      )}
      
      <S.Flexbox>
        <ProjectControl accounts={accounts} explorer={explorer} workspace={workspace} />
        {customerName && <S.Flexbox><Message error>Logged as {customerName}</Message></S.Flexbox>}
        <AccountWidget accounts={accounts} />
      </S.Flexbox>
    </S.Container>
  );
};

export default observer(HeaderPanel);
