import * as dasha from "@dasha.ai/sdk/web";
import { makeObservable, observable } from "mobx";
import storage from "../misc/LocalStorage";

import { AccountFeature, IAccount } from "./interface";
import RemoteLibrary from "./projects/RemoteLibrary";
import Storage from "./filesystem";
import api from "./api";

class Account implements IAccount {
  library = new RemoteLibrary(this);
  features = new Set<AccountFeature>();
  storage: Storage;
  token: string;
  server: string;
  insecure: boolean;

  constructor(readonly name: string, token: string, server: string, insecure: boolean) {
    makeObservable(this, { features: observable });
    this.storage = new Storage(this.name);
    this.setToken(token);
    this.server = server;
    this.insecure = insecure;
  }

  hasFeature(feature: AccountFeature): boolean {
    return this.features.has(feature);
  }

  setValue(key: string, value: unknown) {
    return storage.set(`account:${this.name}:${key}`, value);
  }

  getValue<T>(key: string): T | null {
    return storage.get<T>(`account:${this.name}:${key}`);
  }

  async updateFeatures() {
    this.features.clear();
    //TODO: add api access level checking
  }

  protected setToken(token: string) {
    this.token = token;
    void this.updateFeatures();
    storage.set(`account:${this.name}`, token);
  }

  async connect(): Promise<dasha.Account> {
    return this.connectAs(undefined);
  }

  async connectAs(customerId: string|undefined): Promise<dasha.Account> {
    return { insecure: this.insecure, server: this.server, apiKey: this.token, customerId: customerId ?? this.getValue<string>("customerId") ?? undefined };
  }

  static fromName(name: string, server: string) {
    const token: string | null = storage.get(`account:${name}`);
    if (typeof token !== "string") return null;
    const account = new Account(name, token, server, server.startsWith("localhost"));
    return account;
  }
}

export default Account;
