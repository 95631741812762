import React, { FC, useCallback, useState } from "react";
import { Checkbox, Input, Segment, Table, TextArea } from "semantic-ui-react";
import { ActionButton } from "../..";
import CopyButtonAction from "../../CopyButtonAction";
import { gptOptions } from "./types";
import { observer } from "mobx-react-lite";

interface Props {
  options: gptOptions;
}

const optionNames = {
  model: { type: "string", hint: "openai/gpt-35-turbo" },
  function_call: { type: "string", hint: "auto" },
  openai_apikey: { type: "string", hint: "your apikey" },
  openai_endpoint: { type: "string", hint: "for Azure OpenAI" },
  top_p: { type: "number", hint: "0.5" },
  temperature: { type: "number", hint: "0.5" },
  presence_penalty: { type: "number", hint: "0.0" },
  frequency_penalty: { type: "number", hint: "0.0" },
  max_tokens: { type: "number", hint: "Restrict max tokens in output" },

  ignore_json_output: { type: "boolean" },
  allow_function_name_in_response: { type: "boolean" },
  stop_sequences: { type: "string", hint: "; delimited strings" },

  prepend: { type: "string", hint: "what to say before response" },
  ignore_current_prepend: { type: "number_boolean" },

  sort_history: { type: "boolean" },
  merge_history: { type: "boolean" },
  history_length: { type: "number", hint: "count phrases of any side" },
  log_history: { type: "boolean" },

  thinking_enabled: { type: "boolean" },
  thinking_choices: { type: "number" },
  thinking_fallback_enabled: { type: "boolean" },
  thinking_empty_enabled: { type: "boolean" },
  thinking_multi_answer_enabled: { type: "boolean" },

  retry_temperature_scale: { type: "number", hint: "2.0" },
  retry_topp_scale: { type: "number", hint: "2.0" },
  max_retries: { type: "number", hint: "1" },
  thinking_prompt: { type: "long_string", hint: "" },
  thinking_empty_phrase: { type: "string", hint: "" },
  retry_invalid_function_call: { type: "boolean", hint: "Call a function if allow_function_name_in_response is false" }
};

export const GptOptionsTable: FC<Props> = observer(({ options }) => {
  const [showAllOptions, setShowAllOptions] = useState(false);

  const getOptionCell = (option_name, option_description) => {
    if (option_description.type === "string") {
      return (
        <Input
          placeholder={option_description.hint}
          onChange={(e, data) => options.Update(option_name, data.value)}
          value={options.Get(option_name) ?? ""}
        ></Input>
      );
    }
    if (option_description.type === "long_string") {
      return (
        <TextArea
          placeholder={option_description.hint}
          onChange={(e, data) => options.Update(option_name, data.value?.toString() ?? "")}
          value={options.Get(option_name) ?? ""}
        ></TextArea>
      );
    }
    if (option_description.type === "number") {
      return (
        <Input
          placeholder={option_description.hint}
          onChange={(e, data) => options.Update(option_name, data.value)}
          value={options.Get(option_name) ?? ""}
        ></Input>
      );
    }
    if (option_description.type === "boolean") {
      return (
        <Checkbox
          indeterminate={options.Get(option_name) === undefined}
          onChange={(e, data) => options.Update(option_name, data.checked?.toString()??"false")}
          checked={options.Get(option_name) !== undefined ? options.Get(option_name)?.toLowerCase() === "true" : undefined}
        ></Checkbox>
      );
    }

    if (option_description.type === "number_boolean") {
      return (
        <Checkbox
          indeterminate={options.Get(option_name) === undefined}
          onChange={(e, data) => options.Update(option_name, data.checked ? "1" : "0")}
          checked={options.Get(option_name) !== undefined ? options.Get(option_name) === "1" : undefined}
        ></Checkbox>
      );
    }
    return <></>;
  };

  const getOptionRow = (option_name, option_description) => {
    if (!showAllOptions && options.InitialOptions.get(option_name) === undefined && options.Get(option_name) === undefined) {
      return <></>;
    }
    return (
      <Table.Row>
        <Table.Cell>{option_name}</Table.Cell>
        <Table.Cell>{getOptionCell(option_name, option_description)}</Table.Cell>
        <Table.Cell>
          <ActionButton onClick={() => options.ResetOption(option_name)}>Clear</ActionButton>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Segment style={{overflowY: "auto", maxHeight: '70vh'}}>
      <Checkbox
        toggle
        onChange={(e, data) => setShowAllOptions(data.checked ?? false)}
        checked={showAllOptions}
        label="Show all"
      />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Option</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.HeaderCell>Clear</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(optionNames).map((x) => getOptionRow(x[0], x[1]))}
        </Table.Body>
      </Table>
      <CopyButtonAction tooltipText="Copy Options" clipboard={options.toDSLString()} hasIcon={false}>
              Copy Options
      </CopyButtonAction>
    </Segment>
  );
});
