import React, { FC, useState } from "react";

import { MenuOption, PopupMenu } from "../../uikit/popup";
import { PureButton } from "../../uikit/Button";
import { Icon } from "../../uikit";

import { ActionsWrapper, StyledFile } from "./styled";
import { PlaceholderInput } from "../../uikit/Input/placeholderInput";
import { Tooltip } from "../../uikit/popup";
import { Prompt } from "@core/api/prompts/PromptStore";
import { observer } from "mobx-react-lite";
import { TestCase } from "@core/api/prompts/TestCase";
import UIManager from "@core/misc/UIManager";

interface Props {
  item: TestCase;
  prompt: Prompt;
  indent: number;
  isSelected: boolean;
  onSelect(id: string);
  onRun(id: string, times: number);
}

export const TestItemView: FC<Props> = observer(({ item, prompt, indent, isSelected, onSelect, onRun }) => {
  const [isEditing, setEditing] = useState(false);

  const handleCancel = () => setEditing(false);
  const toggleEditing = () => setEditing(!isEditing);
  const commitDelete = () =>
    prompt.deleteTestCase(item.id).catch((e) => UIManager.notice(`Failed to delete test case ${e}`));

  const commitEditing = (name: string) => {
    item.setName(name);
    item.saveAsync().catch((e) => UIManager.notice(`Failed to save test case ${e}`));
    setEditing(false);
  };

  const actions: MenuOption[] = [
    {
      label: "Rename",
      action: toggleEditing,
      isEnable: true,
    },
    {
      label: "Remove",
      action: commitDelete,
      isEnable: true,
    },
    {
      label: "Run",
      action: () => onRun(item.id, 1),
      isEnable: true,
    },
    {
      label: "Run x3",
      action: () => onRun(item.id, 3),
      isEnable: true,
    },
  ];

  if (isEditing)
    return (
      <PlaceholderInput
        style={{ paddingLeft: indent }}
        defaultValue={item.name}
        onSubmit={commitEditing}
        onCancel={handleCancel}
      />
    );

  const getIcon = () => {
    switch (item.status) {
      case "Running":
      case "Queued":
        return <Icon name="spinner" width={16} />;
      case "Failed":
        return (
          <>
            <Icon name="run" onClick={() => onRun(item.id, 1)} />
            <Icon name="test-failed" />
          </>
        );
      case "Succeed":
        return (
          <>
            <Icon name="run" onClick={() => onRun(item.id, 1)} />
            <Icon name="test-success" />
          </>
        );
    }
    return <Icon name="run" onClick={() => onRun(item.id, 1)} />;
  };

  return (
    <Tooltip text={item.description}>
      <StyledFile isSelected={isSelected} onClick={() => onSelect?.(item.id)} style={{ paddingLeft: indent }}>
        <ActionsWrapper>
          <p>
            {getIcon()}
            &nbsp;&nbsp;{item.name}&nbsp;{item.getStatusCount()}
          </p>

          {
            <PopupMenu actions={actions} closeAfterSelect on="click" position={["bottom center", "top center"]}>
              <PureButton>
                <Icon name="menu" />
              </PureButton>
            </PopupMenu>
          }
        </ActionsWrapper>
      </StyledFile>
    </Tooltip>
  );
});
