import React from "react";
import styled, { keyframes } from "styled-components";

import { ReactComponent as CheckIcon } from "./icons/check.svg";
import { ReactComponent as ArrowIcon } from  "./icons/arrow.svg";
import { ReactComponent as CallIcon } from  "./icons/call.svg";
import { ReactComponent as FileIcon } from  "./icons/file.svg";
import { ReactComponent as MicrophoneIcon } from  "./icons/microphone.svg";
import { ReactComponent as ArrowUpIcon } from  "./icons/arrow-up.svg";
import { ReactComponent as ArrowLeftIcon } from  "./icons/arrow-left.svg";
import { ReactComponent as CursorIcon } from  "./icons/cursor.svg";
import { ReactComponent as CursorUpIcon } from  "./icons/cursor-up.svg";
import { ReactComponent as CursorDownIcon } from  "./icons/cursor-down.svg";
import { ReactComponent as CursorBackIcon } from  "./icons/cursor-back.svg";
import { ReactComponent as SpinnerIcon } from "./icons/spinner.svg";
import { ReactComponent as DownloadIcon } from  "./icons/download.svg";
import { ReactComponent as RestoreIcon } from  "./icons/restore.svg";
import { ReactComponent as DraftFileIcon } from  "./icons/draft-file.svg";
import { ReactComponent as LogoutIcon } from  "./icons/logout.svg";
import { ReactComponent as ChevronDownIcon } from  "./icons/chevron-down.svg";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";
import { ReactComponent as LinkIcon } from  "./icons/link.svg";
import { ReactComponent as ArchiveIcon } from  "./icons/archive.svg";
import { ReactComponent as QuestionIcon } from  "./icons/question.svg";
import { ReactComponent as CrossIcon } from  "./icons/cross.svg";
import { ReactComponent as EditIcon } from  "./icons/edit.svg";
import { ReactComponent as CheckinIcon } from  "./icons/checkin.svg";
import { ReactComponent as InfoIcon } from  "./icons/info.svg";
import { ReactComponent as PlusIcon } from  "./icons/plus.svg";
import { ReactComponent as GenerateIcon } from  "./icons/generate.svg";
import { ReactComponent as TrashIcon } from  "./icons/trash.svg";
import { ReactComponent as WarningIcon } from  "./icons/warning.svg";
import { ReactComponent as LabIcon } from  "./icons/lab.svg";
import { ReactComponent as CloseIcon } from  "./icons/close.svg";
import { ReactComponent as CodeIcon } from  "./icons/code.svg";
import { ReactComponent as AppIcon } from  "./icons/app-icon.svg";
import { ReactComponent as DslIcon } from  "./icons/dsl.svg";
import { ReactComponent as UploadIcon } from  "./icons/upload.svg";
import { ReactComponent as AppsIcon } from  "./icons/apps.svg";
import { ReactComponent as CopyLinkIcon } from  "./icons/copy-link.svg";
import { ReactComponent as FiltersIcon } from  "./icons/filter-icon.svg";
import { ReactComponent as SettingsIcon } from  "./icons/settings-icon.svg";

import { ReactComponent as CreateDirIcon } from  "./icons/create-dir.svg";
import { ReactComponent as CreateFileIcon } from  "./icons/create-file.svg";
import { ReactComponent as UploadFileIcon } from  "./icons/upload-file.svg";

import { ReactComponent as TestFailedIcon } from  "./icons/test-failed.svg";
import { ReactComponent as TestSuccessIcon } from  "./icons/test-success.svg";
import { ReactComponent as DirOpenIcon } from  "./icons/dir-open.svg";
import { ReactComponent as DirCloseIcon } from  "./icons/dir-close.svg";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(SpinnerIcon)`
  animation: ${spin} 2s infinite linear;
`;

const icons = {
  arrow: ArrowIcon,
  call: CallIcon,
  file: FileIcon,
  logout: LogoutIcon,
  "chevron-down": ChevronDownIcon,
  "draft-file": DraftFileIcon,
  microphone: MicrophoneIcon,
  "arrow-up": ArrowUpIcon,
  "arrow-left": ArrowLeftIcon,
  menu: MenuIcon,
  cursor: CursorIcon,
  "cursor-up": CursorUpIcon,
  "cursor-down": CursorDownIcon,
  "cursor-back": CursorBackIcon,
  spinner: Spinner,
  download: DownloadIcon,
  restore: RestoreIcon,
  link: LinkIcon,
  check: CheckIcon,
  archive: ArchiveIcon,
  question: QuestionIcon,
  cross: CrossIcon,
  edit: EditIcon,
  checkin: CheckinIcon,
  info: InfoIcon,
  plus: PlusIcon,
  trash: TrashIcon,
  generate: GenerateIcon,
  warning: WarningIcon,
  lab: LabIcon,
  close: CloseIcon,
  code: CodeIcon,
  "app-icon": AppIcon,
  dsl: DslIcon,
  upload: UploadIcon,
  apps: AppsIcon,
  "copy-link-icon": CopyLinkIcon,
  "filter-icon": FiltersIcon,
  "settings-icon": SettingsIcon,

  "create-dir": CreateDirIcon,
  "create-file": CreateFileIcon,
  "upload-file": UploadFileIcon,
  "test-failed": TestFailedIcon,
  "test-success": TestSuccessIcon,
  "dir-open": DirOpenIcon,
  "dir-close": DirCloseIcon,
};

interface Props {
  style?: any;
  name: keyof typeof icons;
  color?: string;
  angle?: number;
  [key: string]: any;
}

export const Icon = React.forwardRef<SVGElement, Props>(({ name, angle = 0, color, style = {}, ...rest }, ref) => {
  const IconComponent = icons[name] as any;
  const styles = { ...style, transition: ".2s transform", transform: `rotateZ(${angle}deg)`, fill: color };
  return <IconComponent {...rest} ref={ref} style={styles} />;
});
